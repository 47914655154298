import './bootstrap';

console.log('test app')
import 'admin-lte';
import Swal from 'sweetalert2';
import DataTable from 'datatables.net-dt';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import 'datatables.net-buttons-dt';
import 'datatables.net-responsive-dt';
import 'datatables.net-fixedheader-dt';
import 'bootstrap-icons/font/bootstrap-icons.scss'

import { coreAppi } from './core.js'

window.Swal = Swal;
window.coreAppi = coreAppi







